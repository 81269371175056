// src/App.tsx

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './App.css';
import logo from './logo.svg';
import Login from './Login';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState<string | null >();


  useEffect(() => {
    const authCookie = Cookies.get('auth');
    const userCookie = localStorage.getItem('userK'); // Get the user from localStorage

    if (authCookie === 'true') {
      setIsAuthenticated(true);
    }
    setUserName(userCookie); // Store the user name in state

  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const loadScript = () => {
        window.chatwootSettings = { position: 'right', type: 'expanded_bubble', launcherTitle: 'Start a Conversation' };
        const BASE_URL = "https://chatservice.kudzutech.com";
        const script = document.createElement('script');
        let websiteToken = 'Q1fX6JNoWinSb4DdRET7qQiZ'
        if (userName === "ricoCamp") {
          websiteToken = 'Ni5Sq6udU9MznKYwv59Lj8Kd';
        }else if (userName === "jobs4Adv") {
          websiteToken = 'NcQoGgJgGsH2iLQsAZHPdaz2';
        }else if (userName === "TheRealKamala") {
          websiteToken = 'TnpNCoRNm45RFG6B3JYzsP2N';
        }
        script.src = `${BASE_URL}/packs/js/sdk.js`;
        script.defer = true;
        script.async = true;
        script.onload = () => {
          (window as any).chatwootSDK.run({
            websiteToken: websiteToken,
            baseUrl: BASE_URL
          });
        };
        document.body.appendChild(script);
      };

      loadScript();
    }
  }, [isAuthenticated, userName]);

  const handleLogin = () => {
    setIsAuthenticated(true);
    Cookies.set('auth', 'true', { expires: 1 }); // Expires in 1 day
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    Cookies.remove('auth');
  };

  if (!isAuthenticated) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="App">
      <header className="App-header">
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </header>
      <main className="App-body">
        <img src={logo} className="App-logo" alt="logo" />
        <div id="widget-container"></div>
      </main>
    </div>
  );
};

export default App;
