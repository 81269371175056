// src/Login.tsx

import React, { useState } from 'react';
import './Login.css';
import logo from './logo.svg'; // Adjust the path according to your project structure

const Login: React.FC<{ onLogin: () => void }> = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Define a list of valid users with encoded credentials for simplicity
  const validCredentials = [
    { username: btoa('adminK'), password: btoa('kudzu123K') },
    { username: btoa('ricoCamp'), password: btoa('campRico2024!K') },
    { username: btoa('TheRealKamala'), password: btoa('CantTrustKamala123!') },
    { username: btoa('jobs4Adv'), password: btoa('jobsAdvocacy2024!JJ') },
    // { username: btoa('guest'), password: btoa('guest123') }
  ];

  const handleLogin = () => {
    // Check if any user in the array matches the entered credentials
    const isValidUser = validCredentials.some(
      user => btoa(username) === user.username && btoa(password) === user.password
    );

    if (isValidUser) {
      localStorage.setItem('userK', username); // Set user in localStorage
      onLogin();
      setTimeout(() => window.location.reload(), 100); // Delay the reload to ensure navigation has initiated

    } else {
      setError('Incorrect username or password.');
    }
  };



  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <img src={logo} className="login-logo" alt="logo" />
        <h2>Demo</h2>
      </div>
      <div>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </div>
      {error && <div className="error">{error}</div>}
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;
